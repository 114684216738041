<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Edit Booking</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col>
                <h3 class="mb-4 primary--text">1. Customer Info</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.customer_name"
                  :rules="[v => !!v || 'Name is required']"
                  label="Full Name"
                  outlined
                  required
                  dense
                  autofocus
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.customer_email"
                  :rules="[v => !!v || 'Email is required']"
                  label="Email"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.customer_mobile"
                  label="Mobile"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.customer_phone"
                  label="Phone"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="!booking"
                v-user-can:UPDATE_USERS_STRIPE_SETTINGS
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.customer_stripe_id"
                  label="Stripe ID"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                v-if="booking"
                cols="12"
                sm="6"
              >
                <v-checkbox
                  v-model="form.update_customer"
                  label="Update Customer Record?"
                />
              </v-col>
            </v-row>

            <v-divider class="mb-6" />

            <v-row>
              <v-col>
                <h3 class="mb-4 primary--text">2. Other Info</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.po_number"
                  label="PO Number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <FormErrors ref="formErrors" />

          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="updateCustomer"
          >
            {{ booking ? 'Update Booking' : 'Update Customer' }} <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Booking updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the customer.
    </SnackBar>

  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'CustomerEditDialog',

  mixins: [HasDialog, HasForm],

  components: {
    SnackBar,
    FormErrors
  },

  props: {
    booking: {
      type: Object,
      required: false,
      default: () => { return null }
    }
  },

  data () {
    return {
      loading: false
    }
  },

  watch: {
    dialog: {
      immediate: true,
      handler () {
        this.form = {
          ...this.booking
        }
      },
    },
  },

  methods: {
    updateCustomer () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$refs.formErrors.clear()

        if (this.booking) {
          this.$api.persist('put', {
            path: `bookings/${this.booking.id}`,
            object: {
              ...this.form,
              ...{
                update_customer: this.form.update_customer
              }
            }
          })
            .then(() => {
              this.$refs.successBar.open()
              this.closeDialog()
            })
            .catch(error => {
              this.$refs.formErrors.setErrors(error)
              this.$refs.errorBar.open()
            })
            .finally(() => { this.loading = false })
        } else {
          this.$api.persist('put', {
            path: `customers/${this.customer.id}`,
            object: this.form
          })
            .then(() => {
              this.$refs.successBar.open()
              this.closeDialog()
            })
            .catch(error => {
              this.$refs.formErrors.setErrors(error)
              this.$refs.errorBar.open()
            })
            .finally(() => { this.loading = false })
        }
      }
    }
  }
}
</script>
