<template>
  <div>
    <div class="d-flex justify-content-between overflow-x-hidden w-full">
      <h4>
        <v-icon
          v-if="icon"
          small
        >
          {{ icon }}
        </v-icon>
        {{ title }}
        <v-icon
          v-if="showRefresh"
          class="refresh_icon"
          color="primary"
          small
          @click="getData"
        >
          mdi-refresh
        </v-icon>
      </h4>
      <v-icon
        v-if="showAdd"
        color="primary"
        small
        @click="dialog = true"
      >
        mdi-plus-circle-outline
      </v-icon>
    </div>

    <v-divider v-if="spaceAtTop" />

    <v-card
      :loading="loading"
      :class="{
        'mt-2': spaceAtTop
      }"
      outlined
      tile
    >
      <v-card-text>
        <strong v-if="subtitle" class="d-block black--text mb-2">{{ subtitle }}</strong>
        <v-simple-table
          v-if="data && data.length > 0"
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(col, key) in cols"
                  :key="key"
                  class="text-left"
                >
                  {{ col.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in data"
                :key="item.id"
                :class="rowClass(item)"
              >
                <td
                  v-for="(col, key) in cols"
                  :key="key"
                  :class="colClass(item, col)"
                >
                  <slot
                    :item="item"
                    :name="`item.${col.value}`"
                  >
                    {{ item[col.value] }}
                  </slot>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <span v-else>No data to show</span>
      </v-card-text>
    </v-card>

    <slot
      name="addNew"
      :dialog="dialog"
      :close="() => { dialog = false }"
    />

  </div>
</template>
<script type="text/javascript">
import HasRequestParams from '@/mixins/HasRequestParams'

export default {
  name: 'ListAndAddCard',

  mixins: [HasRequestParams],

  props: {
    colClass: {
      type: Function,
      required: false,
      default: () => {}
    },

    showRefresh: {
      type: Boolean,
      required: false,
      default: () => { return true }
    },

    rowClass: {
      type: Function,
      required: false,
      default: () => {}
    },

    showAdd: {
      type: Boolean,
      required: false,
      default: () => { return true }
    },
    path: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      required: false,
      default: () => { return null }
    },

    icon: {
      type: String,
      required: false,
      default: () => { return null }
    },

    wsChannel: {
      type: String,
      required: false,
      default: () => { return null }
    },

    wsEvent: {
      type: String,
      required: false,
      default: () => { return null }
    },

    cols: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },

  data () {
    return {
      loading: false,
      dialog: false,
      data: []
    }
  },

  computed: {
    spaceAtTop () {
      return this.title || this.icon || this.showAdd
    }
  },

  mounted () {
    if (this.wsChannel && this.wsEvent && window.Echo) {
      window.Echo.private(this.wsChannel)
        .listen(`${this.wsEvent}Created`, () => {
          this.getData()
        })
        .listen(`${this.wsEvent}Updated`, () => {
          this.getData()
        })
        .listen(`${this.wsEvent}Deleted`, () => {
          this.getData()
        })
    }
  },

  watch: {
    path: {
      handler () {
        this.getData()        
      },
      immediate: true
    }
  },

  methods: {
    getData () {
      this.loading = true
      this.$api.get({
        path: this.path,
        params: {
          ...{ limit: 50, orderBy: 'created_at', 'ascending': false },
          ...this.requestParams
        }
      })
        .then(({ data }) => {
          this.data = data
        })
        .catch(() => {})
        .finally(() => this.loading = false)
    }
  }
}
</script>
<style type="text/css">
.refresh_icon {
  margin-bottom: 1px;
}
</style>
